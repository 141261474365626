@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Upright:wght@500&display=swap');

/* Global CSS */
body {
  margin: 0;
  transition: .3s;
  overflow-x: hidden;
}

input:focus {
  outline-style: none;
}

textarea:focus {
  outline-style: none;
}

button:focus {
  outline-style: none;
}

p {
  margin: 0;
  font-family: 'Cormorant Garamond', serif;
  /* font-family: 'Cormorant Upright', serif; */

}

h1 {
  font-family: 'Cormorant Garamond', serif;
  /* font-family: 'Cormorant Upright', serif; */

  font-size: 45px;
  margin: 0;
}

/* Home CSS */
.dimmer {
  display: none;
  background: #000;
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

/* Footer CSS */

#footer-label {
  font-family: 'Lato', sans-serif;
  color: #4d77a7;
  font-size: 24px;
  position: absolute;
  top: 25px;
  left: 150px;
  transition: .4s;
}

/* Navbar CSS */
.navbar {
  z-index: 10;
  background: white;
}

#navbar-header {
  text-align: center;
  line-height: 10px;
  font-size: 35px;
  font-weight: bold;
  margin: 0;
  visibility: hidden;
  font-family: 'Cormorant Upright', serif;


}

.navbar-chin {
  width: 20%;
  height: 35px;
  background-color: white;
  border-radius: 0px 0px 20px 20px;
  margin: 0 auto;
  position: absolute;
  bottom: -35px;
  left: 50%;
  transform: translate(-50%, 0);
  transition: .2s;
}

.navbar-chin:hover {
  cursor: pointer;
}

.navbar-chin #left {
  content: "";
  position: absolute;

  background-color: transparent;
  bottom: -15px;
  left: -23.5px;
  height: 50px;
  width: 25px;
  border-top-right-radius: 100px;
  box-shadow: 0 -25px 0 0 white;
  transition: .2s;
}

.navbar-chin #right {
  content: "";
  position: absolute;

  background-color: transparent;
  bottom: -15px;
  right: -23.5px;
  height: 50px;
  width: 25px;
  border-top-left-radius: 100px;
  box-shadow: 0 -25px 0 0 white;
  transition: .2s;
}

.logout-button {
  width: 55px;
  height: 50px;
  background-color: #4f7aab;
  border-radius: 0px 0px 15px 15px;
  border: none;
  right: 25px;
  position: absolute;
  transition: .3s ease;
  display: none;
}

#logout-icon {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, 0);
  transition: .2s;
}

.logout-button:hover {
  height: 70px;
  cursor: pointer;
}

.logout-button:hover > #logout-icon {
  top: 40%;
}

/* Change Background Pop-up */
.change-background-popup {
  width: 40%;
  height: 200px;
  background: white;
  border-radius: 25px;
  z-index: 110;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: .2s;
  visibility: hidden;
  opacity: 0;
  box-shadow: 0px 0px 10px;
}

.selected-image-container {
  width: 40%;
  height: 100px;
  position: absolute;
  top: 10%;
  left: 5%;
}

.change-background-popup #main-label {
  font-size: 20px;
  position: absolute;
  width: 55%;
  right: -5%;
  top: 20%;
  font-weight: bold;
  white-space: break-spaces;
}

.change-background-popup button {
  width: 43.5%;
  height: 45px;
  position: absolute;
  bottom: 15px;
  border-radius: 15px;
  font-family: 'Cormorant Garamond', serif;
  font-size: 20px;
  transition: .2s;
}

.change-background-popup button:hover {
  width: 44.5%;
  height: 47px;
  cursor: pointer;
}

.change-background-popup #cancel {
  left: 5%;
  border: 1px solid #efefef;
}

.change-background-popup #confirm {
  left: 52.5%;
  background-color: #679edf;
  border: none;
  color: white;
}

.selected-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}

/* Image Header CSS */
.image-header-container {
  width: 100%;
  height: 120vh;

  background-image: url('#');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  margin: 0 auto;
  position: relative;
}

.image-header-container input {
  display: none;
}

.view-more-button {
  width: 20%;
  height: 50px;
  border: none;
  border-radius: 10px;
  font-family: 'Cormorant Garamond', serif;
  font-size: 20px;
  color: #679edf;
  background-color: white;
  position: absolute;
  bottom: 25%;
  left: 50%;
  transform: translate(-50%, 0);
  transition: .2s;
  box-shadow: 0px 0px 5px;
}

.view-more-button:hover {
  width: 21%;
  height: 52px;
  box-shadow: 0px 0px 10px;
  cursor: pointer;
  background: #679edf;
  color: white;
}

/* Change About Image CSS */
.change-about-popup {
  width: 40%;
  height: 300px;
  background: white;
  border-radius: 25px;
  z-index: 110;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: .2s;
  visibility: hidden;
  opacity: 0;
  box-shadow: 0px 0px 10px;
}

.change-about-popup #main-label {
  font-size: 20px;
  position: absolute;
  width: 55%;
  right: 0;
  top: 20%;
  font-weight: bold;
  white-space: break-spaces;
}

.change-about-popup button {
  width: 43.5%;
  height: 45px;
  position: absolute;
  bottom: 15px;
  border-radius: 15px;
  font-family: 'Cormorant Garamond', serif;
  font-size: 20px;
  transition: .2s;
}

.change-about-popup button:hover {
  width: 44.5%;
  height: 47px;
  cursor: pointer;
}

.change-about-popup #cancel {
  left: 5%;
  border: 1px solid #efefef;
}

.change-about-popup #confirm {
  left: 52.5%;
  background-color: #679edf;
  border: none;
  color: white;
}

.change-about-popup .selected-image-container {
  width: 190px;
  height: 190px;
  position: absolute;
  top: 10%;
  left: 5%;
}

.change-about-popup .selected-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

/* About CSS */
.about-container {
  width: 100%;
  height: 90vh;
  background-color: #679edf;
  position: relative;
  /* overflow: hidden; */
}

#about-container-header {
  font-size: 40px;
  color: white;
  line-height: 45px;
  position: absolute;
  left: 5%;
  top: 5%;
}

.about-head {
  width: 20%;
  height: 35px;
  background-color: #679edf;
  border-radius: 20px 20px 0px 0px;
  margin: 0 auto;
  position: absolute;
  top: -35px;
  left: 2.5%;
  transition: .2s;
}

.about-head:hover {
  cursor: pointer;
}

.about-head #left {
  content: "";
  position: absolute;

  background-color: transparent;
  bottom: 0px;
  left: -23.5px;
  height: 50px;
  width: 25px;
  border-bottom-right-radius: 100px;
  box-shadow: 0 25px 0 0 #679edf;
  transition: .2s;
}

.about-head #right {
  content: "";
  position: absolute;

  background-color: transparent;
  bottom: 0px;
  right: -23.5px;
  height: 50px;
  width: 25px;
  border-bottom-left-radius: 100px;
  box-shadow: 0 25px 0 0 #679edf;
  transition: .2s;
}

.about-image {
  width: 175px;
  height: 175px;
  position: absolute;
  top: -25%;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 50%;
  background-color: #e2e2e2;
  border: 6px solid #679edf;
}

.about-image input {
  display: none;
}

.about-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  display: none;
}

.info-container {
  position: relative;
  width: 50%;
  /* min-width: 500px; */
  min-height: 400px;
  max-height: 400px;
  top: 25%;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 15px;
  background-color: white;
  /* overflow: hidden; */
}

.about-me-info-container {
  width: 95%;
  top: 25%;
  left: 50%;
  transform: translate(-50%, 0);
  height: 285px;
  position: absolute;
  overflow: auto;
  overflow-y: scroll;
}

#about-me-info {
  color: #679edf;
  font-size: 19px;
  padding: 20px;
}

#about-me-info-input {
  width: 95%;
  height: 100%;
  background: #efefef;
  border-radius: 15px;
  border: unset;
  padding: 15px;
  font-size: 19px;
  color: #679edf;
  font-family: 'Cormorant Garamond', serif;
  display: none;
  resize: none;
}

.about-edit-icon-container {
  width: 3em;
  height: 3em;
  position: absolute;
  top: 25px;
  left: 25px;
  background-color: white;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  transition: .2s;
  display: none;
}

.about-edit-icon-container:hover {
  cursor: pointer;
  background-color: #efefef;
}

#about-save-edit-icon {
  display: none;
}

.social-media-container {
  position: absolute;
  width: 10%;
  min-width: 140px;
  height: 60px;
  bottom: 15%;
  right: 47.5%;
  border-radius: 15px;
  box-shadow: inset 0px 0px 15px #4e78a7;
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-media-container svg {
  color: #4e78a7;
  transition: .2s;
  margin-left: 5%;
  margin-right: 5%;
}

.social-media-container svg:hover {
  color: white;
  cursor: pointer;
}

/* Albums CSS */
.albums-container {
  width: 100%;
  min-height: 110vh;
  background-color: white;
  position: absolute;
  margin-top: -1%;
}

#albums-container-header {
  font-size: 40px;
  color: #679edf;
  line-height: 45px;
  position: absolute;
  left: 5%;
  top: 5%;
}

.albums-head {
  width: 20%;
  height: 35px;
  background-color: white;
  border-radius: 20px 20px 0px 0px;
  margin: 0 auto;
  position: absolute;
  top: -35px;
  left: 2.5%;
  transition: .2s;
}

.albums-head:hover {
  cursor: pointer;
}

.albums-head #left {
  content: "";
  position: absolute;

  background-color: transparent;
  bottom: 0px;
  left: -23.5px;
  height: 50px;
  width: 25px;
  border-bottom-right-radius: 100px;
  box-shadow: 0 25px 0 0 white;
  transition: .2s;
}

.albums-head #right {
  content: "";
  position: absolute;

  background-color: transparent;
  bottom: 0px;
  right: -23.5px;
  height: 50px;
  width: 25px;
  border-bottom-left-radius: 100px;
  box-shadow: 0 25px 0 0 white;
  transition: .2s;
}

.add-album-button {
  width: 25%;
  height: 50px;
  border: 1px solid #679edf;
  border-radius: 15px;
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translate(-50%, 0);
  background: white;
  color: #679edf;
  font-size: 25px;
  font-family: 'Cormorant Garamond', serif;
  transition: .2s;
  display: none;
}

.add-album-button:hover {
  background-color: #679edf;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.albums {
  width: 100%;
  min-height: 500px;
  margin-top: 10%;
  margin-bottom: 20%;
  text-align: center;
}

#no-albums-label {
  font-size: 45px;
  text-align: center;
  line-height: 500px;
  visibility: hidden;
  opacity: 0;
  transition: .4s;
}

/* Add Album Modal */
.add-album-modal {
  width: 65%;
  height: 500px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 25px;
  box-shadow: 0px 0px 15px;
  visibility: hidden;
  opacity: 0;
  z-index: 100;
  transition: .3s;
}

.album-card {
  width: 29%;
  height: 400px;
  /* box-shadow: 0px 0px 10px; */
  border-radius: 15px;
  position: absolute;
  top: 10%;
  left: 10%;
  transition: 0.8s;
  transform-style: preserve-3d;
  visibility: hidden;
  opacity: 0;
}

.album-card-image-container {
  width: 95%;
  height: 80%;
  margin: 0 auto;
  margin-top: 2.5%;
  background-color: #efefef;
  border-radius: 15px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

#drag-drop-directions {
  font-size: 20px;
  text-align: center;
}

#add-album-title {
  font-size: 20px;
  text-align: center;
  line-height: 25px;
  margin-top: 15px;
}

.album-info {
  width: 40%;
  height: 400px;
  position: absolute;
  top: 10%;
  right: 10%;
}

#add-album-title-input {
  display: block;
  width: 80%;
  height: 50px;
  margin: 0 auto;
  margin-top: 2.5%;
  border: none;
  background-color: #efefef;
  border-radius: 15px;
  text-indent: 1em;
  font-size: 18px;
  font-family: 'Cormorant Garamond', serif;
}

#album-title-taken-error {
  font-size: 17px;
  color: #db6861;
  text-align: center;
  margin-top: 2.5%;
  visibility: hidden;
  opacity: 0;
  transition: .2s;
}

.album-info button {
  display: block;
  width: 80%;
  height: 50px;
  margin-top: 2.5%;
  border: none;
  border-radius: 15px;
  font-size: 20px;
  font-family: 'Cormorant Garamond', serif;
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translate(-50%, 0);
  transition: .2s;
}

.album-info button:hover {
  width: 82%;
  height: 54px;
  cursor: pointer;
}

#confirm-add-album {
  background-color: #679fdf;
  color: white;
  bottom: 70px;
}

#cancel-add-album {
  /* bottom: 70px; */
}

.album-modal-error {
  position: absolute;
  width: 100%;
  height: 50px;
  background-color: white;
  border-radius: 15px;
  top: 0;
  visibility: hidden;
  opacity: 0;
  transition: .2s;
}

.album-modal-error p {
  font-size: 20px;
  text-align: center;
  line-height: 50px;
  color: #db6861;
  font-weight: bold;
}

.album-card-image-container input {
  display: none;
}

.album-card-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
  visibility: hidden;
  opacity: 0;
  position: absolute;
}

/* Load Album CSS */
.album-page {
  width: 100%;
  min-height: 100vh;
  position: relative;
}

#no-album-images {
  font-size: 45px;
  visibility: hidden;
  opacity: 0;
  transition: .4s;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -250%);
}

.album-images {
  width: 90%;
  min-height: 80vh;
  margin: 0 auto;
  margin-top: 7.5%;
}

/* Back Button CSS */
.back-button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #679fdf;
  position: fixed;
  top: 7.5%;
  left: 2.5%;
  display: flex;
  align-items: center;
  transition: .2s;
  z-index: 10;
}

.back-button p {
  position: absolute;
  width: 145px;
  font-size: 20px;
  color: white;
  line-height: 50px;
  left: 80px;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: .2s;

}

.back-button svg {
  fill: white;
  position: absolute;
  left: 10%;
}

.delete-selected-images {
  width: 20%;
  height: 50px;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 15px;
  border: 1px solid #db6861;
  background-color: #db6861;
  color: white;
  font-family: 'Cormorant Garamond', serif;
  font-size: 20px;
  font-weight: bold;
  transition: .2s;
  visibility: hidden;
  opacity: 0;
}

.delete-selected-images:hover {
  cursor: pointer;
  box-shadow: 0px 5px 15px #63312e;
  width: 21%;
  height: 52px;
  font-size: 21px;
}

.delete-album {
  width: 20%;
  height: 50px;
  position: absolute;
  top: -75px;
  right: 185px;
  border-radius: 15px;
  border: 1px solid #ea7f1b;
  background-color: white;
  color: #ea7f1b;
  font-family: 'Cormorant Garamond', serif;
  font-size: 20px;
  font-weight: bold;
  transition: .2s;
  visibility: hidden;
  opacity: 0;
}

.delete-album:hover {
  cursor: pointer;
  background-color: #ea7f1b;
  color: white;
  font-size: 21px;
}

/* Image View CSS */
.image-view {
  width: 75%;
  height: 90vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 25px;
  box-shadow: 0px 0px 15px;
  visibility: hidden;
  opacity: 0;
  z-index: 100;
  transition: .3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-view svg {
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 50%;
  transition: .3s;
}

.image-view svg:hover {
  background-color: #efefef;
  cursor: pointer;
}

.image-view-container {
  width: 95%;
  height: 85%;
  background-color: #efefef;
  border-radius: 25px;
  overflow: hidden;
}

#image-view-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Admin Tools CSS */
.admin-tools {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #679fdf;
  position: fixed;
  top: 15.5%;
  left: 2.5%;
  transition: .2s;
  z-index: 10;
  display: none;
}

.admin-tools svg {
  position: absolute;
}

.admin-tools #tools-icon {
  left: 20%;
  top: 10px;
}

.admin-tools #edit-icon {
  top: 75px;
  left: 20%;
  visibility: hidden;
  opacity: 0;
}

.admin-tools #exit-edit-icon {
  top: 75px;
  left: 20%;
  visibility: hidden;
  opacity: 0;
}

.admin-tools #edit-icon:hover {
  fill: #c2c2c2;
  cursor: pointer;
}

.admin-tools #exit-edit-icon:hover {
  fill: #c2c2c2;
  cursor: pointer;
}

.admin-tools #upload-icon {
  left: 20%;
  top: 120px;
  visibility: hidden;
  opacity: 0;
}

.admin-tools #upload-icon:hover {
  fill: #c2c2c2;
  cursor: pointer;
}

/* Upload Album Images CSS */
.upload-album-images-modal {
  width: 65%;
  height: 500px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 25px;
  box-shadow: 0px 0px 15px #efefef;
  visibility: hidden;
  opacity: 0;
  z-index: 100;
  transition: .3s;
}
.upload-album-images-modal .drag-drop-area {
  width: 55%;
  height: 350px;
  background: #efefef;
  border-radius: 15px;
  position: absolute;
  top: 10%;
  left: 5%;
}

.upload-album-images-modal input {
  display: none;
}

.upload-album-images-modal #drag-drop-directions {
  line-height: 350px;
}

.upload-album-images-modal button {
  display: block;
  width: 30%;
  height: 50px;
  margin-top: 2.5%;
  border: none;
  border-radius: 15px;
  font-size: 20px;
  font-family: 'Cormorant Garamond', serif;
  position: absolute;
  bottom: 20%;
  right: -10%;
  transform: translate(-50%, 0);
  transition: .2s;
}

.upload-album-images-modal button:hover {
  width: 30.5%;
  height: 51px;
  cursor: pointer;
}

.upload-album-images-modal #cancel {
  border: 1px solid #efefef;
}

.upload-album-images-modal #confirm {
  background-color: #679edf;
  border: none;
  color: white;
  bottom: 32.5%;
}

#upload-album-images-error {
  font-size: 20px;
  color: #db6861;
  position: absolute;
  bottom: 50px;
  left: 20%;
  visibility: hidden;
  opacity: 0;
}

.progress-bar {
  width: 90%;
  height: 50px;
  border: 1px solid #efefef;
  border-radius: 25px;
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, 0);
  visibility: hidden;
  opacity: 0;
}

.loading-bar {
  width: 10%;
  height: 45px;
  background-color: #efefef;
  border-radius: 25px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: .5%;
  transition: .6s;
}

.loading-bar p {
  font-size: 25px;
  text-align: center;
  line-height: 42.5px;
  transition: .2s;
}

/* Delete Images Popup CSS */
.delete-images-popup {
  width: 45%;
  height: 200px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 25px;
  box-shadow: 0px 0px 15px #efefef;
  visibility: hidden;
  opacity: 0;
  z-index: 100;
  transition: .3s;
}

.delete-images-popup #delete-images-label {
  font-size: 25px;
  position: absolute;
  top: 35px;
  left: 50%;
  transform: translate(-50%, 0);
  white-space: nowrap;
}

.delete-images-popup button {
  width: 40%;
  height: 50px;
  border-radius: 15px;
  border: none;
  font-family: 'Cormorant Garamond', serif;
  font-size: 20px;
  transition: .2s;
  position: absolute;
  bottom: 30px;
}

.delete-images-popup button:hover {
  width: 41%;
  height: 55px;
  font-size: 21px;
  cursor: pointer;
}

.delete-images-popup #confirm {
  background-color: #679fdf;
  color: white;
  font-weight: bold;
  left: 50px;
}

.delete-images-popup #cancel {
  right: 50px;
}

/* Delete Album Popup CSS */
.delete-album-popup {
  width: 45%;
  height: 200px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 25px;
  box-shadow: 0px 0px 15px #efefef;
  visibility: hidden;
  opacity: 0;
  z-index: 100;
  transition: .3s;
}

#delete-album-label {
  font-size: 24px;
  text-align: center;
  line-height: 100px;
}

.delete-album-popup button {
  width: 40%;
  height: 50px;
  border-radius: 15px;
  border: none;
  font-family: 'Cormorant Garamond', serif;
  font-size: 20px;
  transition: .2s;
  position: absolute;
  bottom: 30px;
}

.delete-album-popup button:hover {
  width: 40.5%;
  height: 53px;
  font-size: 21px;
  cursor: pointer;
}

.delete-album-popup #confirm {
  background-color: #679fdf;
  color: white;
  font-weight: bold;
  left: 45px;
}

.delete-album-popup #cancel {
  right: 45px;
}

/* Confirm Swap Changes CSS */
.confirm-swap-changes-popup {
  width: 45%;
  height: 200px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 25px;
  box-shadow: 0px 0px 15px #efefef;
  visibility: hidden;
  opacity: 0;
  z-index: 100;
  transition: .3s;
}

#swap-changes-label {
  font-size: 24px;
  text-align: center;
  line-height: 100px;
}

.confirm-swap-changes-popup button {
  width: 40%;
  height: 50px;
  border-radius: 15px;
  border: none;
  font-family: 'Cormorant Garamond', serif;
  font-size: 20px;
  transition: .2s;
  position: absolute;
  bottom: 30px;
}

.confirm-swap-changes-popup button:hover {
  width: 40.5%;
  height: 53px;
  font-size: 21px;
  cursor: pointer;
}

.confirm-swap-changes-popup #confirm {
  background-color: #679fdf;
  color: white;
  font-weight: bold;
  left: 45px;
}

.confirm-swap-changes-popup #cancel {
  right: 45px;
}

/* Login CSS */
.login-container {
  width: 30%;
  height: 400px;
  background-color: white;
  margin: 0 auto;
  margin-top: 15%;
  border-radius: 10px;
  box-shadow: unset;
  transition: .4s;
  overflow: auto;
  position: relative;
  visibility: hidden;
}

.login-container p {
  position: absolute;
  font-size: 25px;
  top: 10%;
  left: 50%;
  transform: translate(-50%, 0);
}

.login-input {
  display: block;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 80%;
  height: 50px;
  border: none;
  border-radius: 10px;
  background-color: #efefef;
  text-indent: 10px;
  font-family: 'Cormorant Garamond', serif;
  font-size: 18px;
}

.login-input#password {
  top: 40%;
}

.login-button {
  width: 80%;
  height: 50px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 15%;
  border: none;
  background-color: #f97777;
  border-radius: 10px;
  font-family: 'Cormorant Garamond', serif;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: .2s;
}

.login-button:hover {
  cursor: pointer;
  width: 82%;
  height: 52px;
  font-size: 18.5px;
}

.login-error-message {
  width: 30%;
  height: 60px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 25px #9a4a4a;
  margin: 0 auto;
  margin-top: -60px;
  visibility: hidden;
  transition: .2s;
}

.login-error-message p {
  line-height: 60px;
  font-size: 18px;
  text-align: center;
  color: #f97777;
  font-weight: bold;
}

/* ---------------------------------------- CSS Spinner ---------------------------------------- */
.sk-circle {
  margin: 100px auto;
  width: 35px;
  height: 35px;
  position: relative;
  margin-top: 2%;
  display: none;
}
.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.sk-circle .sk-child:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: white;
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
          animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}
.sk-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
      -ms-transform: rotate(30deg);
          transform: rotate(30deg); }
.sk-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
      -ms-transform: rotate(60deg);
          transform: rotate(60deg); }
.sk-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg); }
.sk-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
      -ms-transform: rotate(120deg);
          transform: rotate(120deg); }
.sk-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
      -ms-transform: rotate(150deg);
          transform: rotate(150deg); }
.sk-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg); }
.sk-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
      -ms-transform: rotate(210deg);
          transform: rotate(210deg); }
.sk-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
      -ms-transform: rotate(240deg);
          transform: rotate(240deg); }
.sk-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
          transform: rotate(270deg); }
.sk-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
      -ms-transform: rotate(300deg);
          transform: rotate(300deg); }
.sk-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
      -ms-transform: rotate(330deg);
          transform: rotate(330deg); }
.sk-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s; }
.sk-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
          animation-delay: -1s; }
.sk-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s; }
.sk-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s; }
.sk-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s; }
.sk-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s; }
.sk-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s; }
.sk-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s; }
.sk-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s; }
.sk-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s; }
.sk-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s; }

@-webkit-keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  } 40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  } 40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
